import { Component, OnInit } from '@angular/core';
import { Injectable } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { RouteConfigLoadEnd } from '@angular/router';
import { EmployeeService } from '../dashboard/program/employee.service';
import { LoginService } from '../login.service';


@Component({
  selector: 'app-active',
  templateUrl: './active.component.html',
  styleUrls: ['./active.component.css'],
  providers: [LoginService]
})
export class ActiveComponent {
  // users: any = [];
  role = "";
  constructor(private firestore: AngularFirestore,private employeeService: EmployeeService,public logSer: LoginService,) {
    this.logSer.userLog.subscribe(data => {
      if (data!=null && data.role != null) {
        //this.name = data.email;
        this.role = data.role;
        this.getActive(data.role);
        
      }
    });
  }
	act:number = 0;
  inact: number = 0;
  total: number = 0;
  pro= [];
  program:any;
  ngOnInit() {
		this.getActive("");
    
	}
  users = [];
  all = 'all';
  active = 'active';
  inactive = 'inactive';
  filter = 'all';
  goo = '0px';
  fil(i) {
    this.filter = i;
  }
  getActive(role){
    this.employeeService.getActive(role).subscribe((data)=>{
      this.pro = data
				.map((e) => {
					return {
					  program: e.payload.doc.data(),
					};
			})
      this.numb();
    })
    
  }
  numb(){
    for(let j of this.pro){
      if(j.program.InUse){
        this.act++;
      }
      else{
        this.inact++;
      }
    }  
  }
  dor(item) {
    return item ? '#b2e0ad' : 'rgb(255, 160, 160)';
  }
  func(val) {
    if (val) {
      if (this.filter === 'inactive') {
        return false;
      } else {
        return true;
      }
    } else {
      if (this.filter === 'active') {
        return false;
      } else {
        return true;
      }
    }
  }
}
