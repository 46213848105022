import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from '../../../login.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: []
})
export class AppHeaderComponent implements OnInit {
  constructor(private loginSer:LoginService,private router:Router){}
login=false;

onLogin(){
  this.router.navigate(['/login']);
}
ngOnInit(){
  this.loginSer.loginSub.subscribe(value=>{
    this.login = value;
  });
}
}
