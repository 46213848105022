import { Component, OnInit } from "@angular/core";
import { CrudService } from "../dashboard/program/crud.service";
import { AngularFirestore } from "@angular/fire/firestore";

@Component({
	selector: "app-landing2",
	templateUrl: "./landing2.component.html",
	styleUrls: ["./landing2.component.css"],
})
export class Landing2Component implements OnInit {
	constructor(public crudservice: CrudService, firestore: AngularFirestore) {}

	ngOnInit(): void {}
	email = "";
	phone = "";
	nam = "";
  id="";
	
	checkmail() {
		const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return re.test(String(this.email).toLowerCase());
	}
	checkphone() {
		return /^(1\s|1|)?((\(\d{3}\))|\d{3})(\-|\s)?(\d{3})(\-|\s)?(\d{4})$/.test(
			this.phone
		);
	}
	e() {
		if (this.checkmail() === true && this.checkphone() === true) {
			this.crudservice.create_NewUserMH({
				phone: this.phone,
				name: this.nam,
				email: this.email,
			});
			alert('Form Submitted')
		} else {
			alert("Invalid Values");
		}
	}
}