/** @format */

import { Injectable } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";

@Injectable({
	providedIn: "root",
})
export class CrudService {
	constructor(public fireservices: AngularFirestore) {}

	create_NewProgram(Record: any) {
		return this.fireservices.collection("Program").add(Record);
	}
	get_All() {
		return this.fireservices.collection("Program").snapshotChanges();
	}
	create_NewUser(Record: any) {
		return this.fireservices.collection("Early").add(Record);
	}
	create_NewUserMH(Record: any) {
		return this.fireservices.collection("MentalHealth").add(Record);
	}
}
