<div class="container width" style="align-content: center;">
    <div fxLayout="row">
        <div fxFlex.gt-sm="100%" >
                <mat-card style="border-radius: 30px;">
                  <div style="margin-right:10%; padding-top: 50px; padding-bottom: 50px;">
                    <mat-card-title style="padding-top: 3%;text-align: center;">

                    </mat-card-title>
                        <mat-card-content style="padding-left: 50px;padding-top: 10px;">
                            <form #f="ngForm" (submit)="onSubmit()">
                                <div class="example-container" >
                                  <mat-form-field class="width-100">
                                    <mat-label>First Name/(Company/Hospital/Clinic name)</mat-label>
                                    <input matInput name=fname  ngModel required >
                                  </mat-form-field>
                                  <mat-form-field class="width-100">
                                    <mat-label>last Name(for users/doctor)/City, State(For Hospital/Clinic)</mat-label>
                                    <input matInput name=lname placeholder="Ex. pat@example.com" ngModel>
                                  </mat-form-field>
                                  <br>
                                  <mat-form-field  class="width-100">
                                    <mat-label>Email address</mat-label>
                                    <input matInput placeholder="Ex. pat@example.com" name=email required ngModel email>
                                  </mat-form-field>
                                  <br>
                                  <mat-form-field class="width-100">
                                    <mat-label>Enter password</mat-label>
                                    <input matInput [type]="hide ? 'password' : 'text'" name=password ngModel autocomplete="on" required>
                                    <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                                    <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                                    </button>
                                  </mat-form-field>
                                  <br>
                                  <!-- <label id="example-radio-group-label">Gender: </label>
                                  <br><br>
                                  <mat-radio-group aria-label="Select an option" name=gender ngModel required>
                                    <mat-radio-button value="1">Male</mat-radio-button>
                                    <mat-radio-button class="example-radio-button" value="2">Female</mat-radio-button>
                                   </mat-radio-group>
                                  <br><br>
                                  <label id="example-radio-group-label">Date of birth: </label>
                                  <br><br>
                                  <mat-form-field appearance="fill" class="width-25">
                                    <mat-label>Day</mat-label>
                                    <mat-select name=date ngModel required>
                                      <mat-option [value]="6">
                                       6
                                      </mat-option>
                                    </mat-select>
                                  </mat-form-field>
                              
                                  <mat-form-field appearance="fill" class="width-25 ml-1">
                                    <mat-label>Month</mat-label>
                                    <mat-select name=month ngModel required>
                                      <mat-option value="1">1</mat-option>
                                      <mat-option value="2">2</mat-option>
                                      <mat-option value="3">3</mat-option>
                                      <mat-option value="4">4</mat-option>
                                      <mat-option value="5">5</mat-option>
                                      <mat-option value="6">6</mat-option>
                                      <mat-option value="7">7</mat-option>
                                      <mat-option value="8">8</mat-option>
                                      <mat-option value="9">9</mat-option>
                                      <mat-option value="10">10</mat-option>
                                      <mat-option value="11">11</mat-option>
                                      <mat-option value="12">12</mat-option>
                                    </mat-select>
                                  </mat-form-field>
                              
                                  <mat-form-field appearance="fill" class="width-25 ml-1">
                                    <mat-label>Year</mat-label>
                                    <mat-select name=year ngModel required>
                                      <mat-option value="6">
                                       6
                                      </mat-option>
                                    </mat-select>
                                  </mat-form-field> -->
                                 <br>
                                 User Type:
                                 <mat-form-field class="width-50">
                                    <mat-label>User Type</mat-label>
                                    <mat-select name=type ngModel required style="color: black !important;">
                                      <mat-option value="user">
                                       User
                                      </mat-option>
                                      <mat-option value="Doctor">
                                        Doctor(will be verified by admin)
                                       </mat-option>
                                       <mat-option value="vendors">
                                        Clinic/Hospital(will be verified by admin)
                                       </mat-option>
                                       <mat-option value="owners">
                                        Owners(will be verified by admin)
                                       </mat-option>
                                    </mat-select>
                                  </mat-form-field>
                                  <br>
                                
                                 <div style="text-align: center;">
                                   <button type="submit" [disabled]="!f.valid" style="border-radius: 10px;padding: 10px;" class="btn btn-success">SignUp</button> <br><br>
                                Already Registered? <a href="/login"  onmouseover="style='text-decoration:underline;cursor: pointer;'" onmouseout="style='text-decoration:none'">Login
                                 </a> </div>
                                </div>
                            </form>
                                
                        </mat-card-content>
                      </div>        
                </mat-card>
            </div>
        </div>
</div>