/** @format */

import { LoginService } from "./login.service";
import { Component } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";

@Component({
	selector: "app-root",
	templateUrl: "./app.component.html",
	styleUrls: ["./app.component.css"],
	providers: [LoginService],
})
export class AppComponent {
	constructor(private firestore: AngularFirestore) {
		this.firestore
			.collection("users")
			.snapshotChanges()
			.subscribe((res) => {
				res.map((e) => {
					//console.log(e.payload.doc.data());
				});
			});
	}
	land = true;
	done() {
		this.land = false;
	}
}
