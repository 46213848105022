/** @format */

// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
	production: false,
	firebase: {
		apiKey: "AIzaSyDN8o1JLP12DNwKTEGI76nTcCtrzRP7Yiw",
    authDomain: "nowcare4u-17e60.firebaseapp.com",
    databaseURL: "https://nowcare4u-17e60.firebaseio.com",
    projectId: "nowcare4u-17e60",
    storageBucket: "nowcare4u-17e60.appspot.com",
    messagingSenderId: "741741037538",
    appId: "1:741741037538:web:2072da18d4f7d58bb29bbb",
    measurementId: "G-37XHPPGTVW"
	},

};
