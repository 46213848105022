import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { LoginService } from '../login.service';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.css'],
  providers: [LoginService]
})
export class SignUpComponent implements OnInit {
  hide = true;
  constructor(private router:Router,private logSer:LoginService) { }
 @ViewChild('f') form:NgForm;
 status:boolean=true;
 text='';
  ngOnInit(): void {
   
  }
  onSubmit(){
    console.log(this.form.value);
    this.status = this.logSer.signUp(this.form.value);
    if(this.status){
      this.router.navigate(['/user']);
    }
    else{
      this.text = 'The user is already exists';
      console.log('The user is already exists');
    }
  }
}
