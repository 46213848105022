<div class="container width" style="align-content: center;min-height: 100vh;">
    <div fxLayout="row" style="justify-content: center;">
        <div fxFlex="50" fxFlex.gt-xs="80" class="pwdcont"  >
                <mat-card style="border-radius: 30px;margin-top:30vh;">
                  <div style="margin-right:10%; padding-top: 5px; padding-bottom: 5px;">
                        <mat-card-content style="padding-left: 50px;padding-top: 10px;">
                            <form #f="ngForm" (submit)="logSer.resetEmail(form.value.email)">
                                <div class="example-container" >
                                    <mat-form-field  class="width-100">
                                        <mat-label>Email address</mat-label>
                                        <input matInput placeholder="Ex. pat@example.com" name=email #email="ngModel"
                                        [(ngModel)]="mail" required ngModel email>
                                      </mat-form-field>
                                 <div style="text-align: center;">
                                   <button type="submit" (click)="send()" style="border-radius: 10px;padding: 10px;" class="btn btn-success">Send email(reset password)</button> <br><br>
                                </div>
                                </div>
                            </form>
                        </mat-card-content>
                      </div>        
                </mat-card>
            </div>
        </div>
</div>
