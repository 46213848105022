<footer class="footer-section" style="border-radius: 50px 50px 0 0;border-top: 3px solid white;">
    <div class="container-fluid" style="padding-left: 5vw;padding-right: 5vw;">
        <div class="footer-cta pt-5 pb-3">
            <div class="row">
                <div class="col-xl-3 col-md-3 mb-30" style="padding-left: 15px;padding-right: 15px;">
                    <div class="footer-logo">
                        <b>
                            <img src="assets/images/logo_4.png" style="height: auto;  max-height: 75px;" class="light-logo" alt="homepage">
                            <img src="assets/images/logo_1.png" style="height: auto;  max-height: 75px;" class="light-logo" alt="homepage">
                        </b>
                 </div>
                 <div class="footer-text">
                    <p style="font-size: 14px;">Our mission is to reduce the widespread presence of avoidable appealing diseases and improve health care to simple personal and affordable one.</p>
                </div>
                </div>
                <div class="col-xl-2 col-md-2 mb-30 " style="padding-left: 15px;padding-right: 0px;">
                    <div class="footer-text" style="color: white;">
                        <br>
                        <h5 style="font-size: 14px;">Know More</h5>
                            <p style="font-size: 14px;"><a [routerLink]="['/info/about-us']" style="color:white;">About Us</a><br>
                                <a [routerLink]="['/dashboard/blog']" style="color:white;">Our Blogs</a><br>
                                <a [routerLink]="['/dashboard/services']" style="color:white;">Our Services</a>
                            </p>
                        </div>
                </div>

                <div class="col-xl-2 col-md-2 mb-30" style="padding-left: 0px;padding-right: 0px;">
                    <div class="footer-text" style="color: white;">
                        <br>
                        <h5 style="font-size: 14px;">Other Sections</h5>
                            <p style="font-size: 14px;"><a [routerLink]="['/dashboard/ehr']" style="color:white;">EHR</a><br>
                                <a (click)="goToExpert()" onmouseover="style=''" style="color:white;">Experts</a><br>
                                <a (click)="goToCalc()" style="color:white;">calculator</a><br>
                                <a (click)="goToTest()" style="color:white;">Covid Testing</a>
                            </p>
                        </div>
                </div>
                <div class="col-xl-3 col-md-3 mb-30" style="padding-left: 0px;padding-right: 8px;">
                    <div class="footer-text" style="color: white;">
                        <br>
                        <h5 style="font-size: 14px;">Contact Us</h5>
                            <p style="font-size: 14px;"><a [routerLink]="['/info/contact-us']" style="color:white;">Contact Us/Raise a Complaint</a></p>
                          <p style="font-size: 14px;">Corporate address: Door No.72/6, Haily Mandi,Gurgaon, Haryana 122504</p>
                         <p style="font-size: 14px;">Call us: <span>+91-8595591496, 
                                0124-4147286</span></p>
                        </div>
                </div>
               <!--- <div class="col-xl-2 col-md-2 mb-30">
                    <div class="single-cta">
                        <i class="mdi mdi-map" style="color: white;"></i>
                        <div class="cta-text">
                            <h4>Find us</h4>
                            <span>Door No.72/6, Haily Mandi,<br>Gurgaon, Haryana 122504</span>
                            <br><br>
                        </div>
                    </div>
                </div>
                <div class="col-xl-2 col-md-2 mb-30">
                    <div class="single-cta">
                        <i class="mdi mdi-phone" style="color: white;"></i>
                        <div class="cta-text">
                            <h4>Call us</h4>
                            <span>+91-8595591496<br>
                            0124-4147286</span>
                            <br><BR>
                        </div>
                    </div>
                </div>
                <div class="col-xl-2 col-md-2 mb-30">
                    <div class="single-cta">
                        <i class="mdi mdi-email" style="color: white;"></i>
                        <div class="cta-text">
                            <h4>Mail us</h4>
                            <span>contact@newacare4u.com</span>
                        </div>
                    </div>
                </div> -->
                <div class="col-xl-2 col-md-2 mb-30" style="padding-left: 5px;padding-right: 15px;">
                    <div class="footer-social-icon" style="color: white;">
                        <br>
                        <h5 style="font-size: 14px;">Experience Our App</h5>
                        <a href="https://play.google.com/store/apps/details?id=nowcare.com.nowcare4u" target="_blank"><i class="mdi mdi-android" style="color: white;"></i></a>
                        <a href="https://apps.apple.com/app/id1531364454" target="_blank"><i class="mdi mdi-apple" style="color: white;"></i></a>
                        </div>
                        <div class="footer-social-icon" style="color: white;">
                            <br>
                            <h5 style="font-size: 14px;">Follow us <br>
                            <a href="https://www.facebook.com/nowcare4u/" target="_blank"><i class="mdi mdi-facebook" style="color: white;"></i></a>
                            <a href="https://twitter.com/nowcare4u" target="_blank"><i class="mdi mdi-twitter" style="color: white"></i></a>
                            <a href="https://in.linkedin.com/company/nowcare4u" target="_blank"><i class="mdi mdi-linkedin" style="color: white"></i></a>
                            <a href="https://www.instagram.com/nowcare4u" target="_blank"><i class="mdi mdi-instagram" style="color: white"></i></a>
                            </h5>

                        </div>
                </div>
            </div>
        </div>
        <div class="footer-content">
            <div class="row">
                <div class="footer-content-1 col-xl-10 col-lg-10 mb-30">
                    <div class="footer-text">
                        <p style="font-size: 14px;"><strong>Popular Searches:</strong> Anxiety | ADHD | Autism | Bipolar Disorder | Child Psychiatrist | Child Psychologist | Clinical Social Worker | Conduct Disorder | Depression | Developmental Disabilities | Eating Disorder | Mental Illness | Post Traumatic Stress Disorder (PSTD) | Psychotherapy | Reactive Attachment Disorder (RAD) | Sexual Abuse | Substance Abuse / Dependence | Genetic Disorder | Diet | weight loss | fitness  </p>
                    </div>
                </div>
                <div class="col-xl-2 col-lg-2 mb-30">
                    <div class="footer-widget">
                        <div class="footer-logo" style="margin-bottom: unset;">
                            <b>
                                <img src="assets/images/logo_4.png" style="height: auto;  max-height: 80px;margin-left: 2vw;vertical-align: middle;" class="light-logo" alt="homepage">
                            </b>
                            </div>
                            </div>
                </div>
             <!--   <div class="footer-content-1 col-xl-8 col-lg-8 mb-50">
                    <div class="footer-widget">
                        <div class="footer-logo">
                            <b>
                                <img src="assets/images/logo_4.png" style="height: auto;  max-height: 75px;" class="light-logo" alt="homepage">
                                <img src="assets/images/logo_1.png" style="height: auto;  max-height: 90px;" class="light-logo" alt="homepage">
                            </b>
             </div>
                        <div class="footer-text">
                            <p>Our mission is to reduce the widespred presence of avoidable appaling diseases and improve health care to simple personal and affordable one.</p>
                        </div>
                        <div class="footer-social-icon">
                            <span>Follow us <br>
                                <a href="www.facebook.com" target="_blank"><i class="mdi mdi-facebook" style="color: white;"></i></a>
                            <a href="www.twitter.com" target="_blank"><i class="mdi mdi-twitter" style="color: white"></i></a>
                            <a href="www.google.com" target="_blank"><i class="mdi mdi-google" style="color: white"></i></a>
                            </span>

                        </div>
                    </div>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-6 mb-50">
                    <div class="footer-widget">
                        <div class="footer-widget-heading">
                            <h3>Subscribe</h3>
                        </div>
                        <div class="footer-text mb-25">
                            <p>Don’t miss to subscribe to our new feeds, kindly fill the form below.</p>
                        </div>
                        <div class="subscribe-form">
                            <form action="#">
                                <input type="text" placeholder="Email Address" style="color: black;">
                                <button><i class="mdi mdi-telegram"></i></button>
                            </form>
                        </div>
                    </div>
                </div> -->
            </div>
        </div>
       <div class="footer-cta pt-3 pb-3">
            <div class="row">
                <p style="font-size: 14px;" style="color: white;">Disclaimer: NowCare4u.com or SusaKGjyo Bussiness  Pvt.Ltd. does not provide medical advice and does not cater to any medical/Pregnancy or psychiatric emergencies. If you are in a life threatening situation, please do NOT use this site. If you are feeling suicidal we recommend you call a suicide prevention helpline or go to your nearest hospital.</p>
                </div>
                </div> 
    </div>
    <div class="copyright-area">
        <div class="container">
            <div class="row">
                <div class="col-xl-4 col-lg-4 text-center text-lg-left">
                    <div class="copyright-text">
                        <p style="font-size: 14px;">Copyright &copy; 2020, All Right Reserved <a href="#">Nowcare4U</a></p>
                    </div>
                </div>
                <div class="col-xl-8 col-lg-8  text-lg-right text-center">
                    <div class="footer-menu" style="font-size: 14px;">
                        <ul>
                            <li><a [routerLink]="['/dashboard']" onmouseover="style='text-decoration:underline;cursor: pointer;'" onmouseout="style='text-decoration:none'">
                                Home</a></li>
                            <li><a [routerLink]="['/info/term-condition']"   onmouseover="style='text-decoration:underline;cursor: pointer;'" onmouseout="style='text-decoration:none'">
                                Terms And Condition</a></li>
                            <li><a onmouseover="style='text-decoration:underline;cursor: pointer;'" onmouseout="style='text-decoration:none'">
                                Privacy</a></li>
                            <li><a [routerLink]="['/info/return-and-refund-policy']"  onmouseover="style='text-decoration:underline;cursor: pointer;'" onmouseout="style='text-decoration:none'">
                                Return And Refund Policy</a></li>
                            <li><a [routerLink]="['/info/cancellation-policies']" onmouseover="style='text-decoration:underline;cursor: pointer;'" onmouseout="style='text-decoration:none'">Cancellation Policy</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>