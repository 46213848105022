import { AfterViewInit, Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { LoginService } from '../login.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  providers: [LoginService]
})
export class LoginComponent implements OnInit, AfterViewInit {
detail= true;
FormData: FormGroup;
constructor(private builder: FormBuilder, private loginSer: LoginService) { }
ngOnInit() {
this.FormData = this.builder.group({
Email: new FormControl('', [Validators.compose([Validators.required, Validators.email])]),
password: new FormControl('', [Validators.required])
})
}

ngAfterViewInit() {
  // Hack: Scrolls to top of Page after page view initialized
  setTimeout(() => {
    let top = document.getElementById('top');
  if (top !== null) {
    top.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'start'});
    top = null;
  }
  }, 10);
}

  onSubmit(FormData) {
  this.detail = this.loginSer.loginAcc(FormData.value);
  this.FormData.setValue({password: FormData.value.password, Email: FormData.value.Email});
  console.log(this.FormData);
  }

  
}
