<!-- @format -->

<div class="scrollbar" style="overflow-y: hidden">
	<div class="overflow">
		<div
			class="user-profile"
			style="
				background: url(assets/images/background/user-info.jpg)
					no-repeat;
			"
		>
			<div class="profile-img" style="padding-bottom: 15px">
				<img src="assets/images/users/profile.png" alt="user" />
			</div>
			<div
				class="profile-txt text-center"
				style="color: rgb(233, 52, 52); font-weight: bolder"
				*ngIf="dlogin"
			>
				Welcome Doctor!!
			</div>
			<div
				class="profile-txt text-center"
				style="color: rgb(233, 52, 52); font-weight: bolder"
				*ngIf="isvendor"
			>
				Welcome Hospital!!
			</div>
			<div
				class="profile-txt text-center"
				style="color: rgb(233, 52, 52); font-weight: bolder"
				*ngIf="isadmin"
			>
				Welcome Admin!!
			</div>
			<div
				class="profile-txt text-center"
				style="color: rgb(233, 52, 52); font-weight: bolder"
				*ngIf="isowner"
			>
				Welcome Owner!!
			</div>
			<div class="profile-text" *ngIf="!dlogin && login">
				<a
					[matMenuTriggerFor]="sdprofile"
					class=""
					style="padding-bottom: 0px; padding-left: 5px"
					>{{ name }}<i class="ti-angle-down font-12 m-l-5"></i
				></a>
			</div>
			<div class="profile-text" *ngIf="dlogin">
				<a
					[matMenuTriggerFor]="sdprofile"
					class=""
					style="padding-bottom: 0px; padding-left: 5px"
					>{{ dname }}<i class="ti-angle-down font-12 m-l-5"></i
				></a>
			</div>
			<mat-menu #sdprofile="matMenu" class="mymegamenu">
				<button
					mat-menu-item
					[routerLink]="['/user']"
					routerLinkActive="router-link-active"
				>
					<mat-icon>account_box</mat-icon> Profile
				</button>
				<button mat-menu-item (click)="logSer.changeStatus()">
					<mat-icon>exit_to_app</mat-icon> Sign Out
				</button>
				<button mat-menu-item (click)="logSer.resetEmail(name)">
					<mat-icon>exit_to_app</mat-icon> reset password
				</button>
			</mat-menu>
		</div>
		<mat-nav-list appAccordion>
			<mat-list-item appAccordionLink routerLinkActive="selected">
				<a
					class=""
					appAccordionToggle
					[routerLink]="['/dashboard']"
					routerLinkActive="router-link-active"
				>
					<mat-icon>home</mat-icon>
					<span>Home</span>
					<span fxFlex></span>
				</a>
			</mat-list-item>
			<mat-list-item appAccordionLink routerLinkActive="selected">
				<a
					class=""
					appAccordionToggle
					[routerLink]="['/user']"
					routerLinkActive="router-link-active"
				>
					<mat-icon>portrait</mat-icon>
					<span>Update Profile</span>
					<span fxFlex></span>
				</a>
			</mat-list-item>
			<mat-list-item appAccordionLink routerLinkActive="selected">
				<a
					class=""
					appAccordionToggle
					[routerLink]="['/user/appointment']"
					routerLinkActive="router-link-active"
				>
					<mat-icon>av_timer</mat-icon>
					<span>Appointment</span>
					<span fxFlex></span>
				</a>
			</mat-list-item>
			<mat-list-item appAccordionLink routerLinkActive="selected">
				<a
					class=""
					appAccordionToggle
					[routerLink]="['/dashboard/ehr']"
					routerLinkActive="router-link-active"
				>
					<mat-icon>assignment</mat-icon>
					<span>EHR</span>
					<span fxFlex></span>
				</a>
			</mat-list-item>
			<mat-list-item appAccordionLink routerLinkActive="selected">
				<a
					class=""
					appAccordionToggle
					[routerLink]="['/dashboard/programs']"
					routerLinkActive="router-link-active"
				>
					<mat-icon>fact_check</mat-icon>
					<span>Program</span>
					<span fxFlex></span>
				</a>
			</mat-list-item>
			<mat-list-item  appAccordionLink routerLinkActive="selected">
				<a
					class="btn"
					*ngIf = "isowner"
					appAccordionToggle
					[routerLink]="['/active']"
					routerLinkActive="router-link-active"
				>
					<mat-icon>check</mat-icon>
					<span>Active</span>
					<span fxFlex></span>
				</a>
			</mat-list-item>

			<mat-list-item
				appAccordionLink
				routerLinkActive="selected"
				*ngIf="isowner || isadmin"
			>
				<a
					class=""
					appAccordionToggle
					[routerLink]="['/user/ownerlicence']"
					routerLinkActive="router-link-active"
				>
					<mat-icon>dns</mat-icon>
					<span>Owner licence</span>
					<span fxFlex></span>
				</a>
			</mat-list-item>
			<mat-list-item
				appAccordionLink
				routerLinkActive="selected"
				*ngIf="isadmin"
			>
				<a
					class=""
					appAccordionToggle
					[routerLink]="['/user/requests']"
					routerLinkActive="router-link-active"
				>
					<mat-icon>dns</mat-icon>
					<span>Requests</span>
					<span fxFlex></span>
				</a>
			</mat-list-item>
			<mat-list-item
				appAccordionLink
				routerLinkActive="selected"
				*ngIf="isadmin"
			>
				<a
					class=""
					appAccordionToggle
					[routerLink]="['/user/resume']"
					routerLinkActive="router-link-active"
				>
					<mat-icon>dns</mat-icon>
					<span>Resume</span>
					<span fxFlex></span>
				</a>
			</mat-list-item>
			<mat-list-item
				appAccordionLink
				routerLinkActive="selected"
				*ngIf="dlogin"
			>
				<a
					class=""
					appAccordionToggle
					[routerLink]="['/user/history']"
					routerLinkActive="router-link-active"
				>
					<mat-icon>dns</mat-icon>
					<span>Prescription History</span>
					<span fxFlex></span>
				</a>
			</mat-list-item>
			<mat-list-item
				appAccordionLink
				routerLinkActive="selected"
				*ngIf="isadmin"
			>
				<a
					class=""
					appAccordionToggle
					[routerLink]="['/user/licence']"
					routerLinkActive="router-link-active"
				>
					<mat-icon>note_add</mat-icon>
					<span>Licence</span>
					<span fxFlex></span>
				</a>
			</mat-list-item>
			<mat-list-item
				appAccordionLink
				routerLinkActive="selected"
				*ngIf="isadmin"
			>
				<a
					class=""
					appAccordionToggle
					[routerLink]="['/demo']"
					routerLinkActive="router-link-active"
				>
					<mat-icon>view_week</mat-icon>
					<span>Blog updates</span>
					<span fxFlex></span>
				</a>
			</mat-list-item>
		</mat-nav-list>
	</div>
</div>
