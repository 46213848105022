/** @format */

import { ForgotpwdComponent } from "./forgotpwd/forgotpwd.component";
import { LoginService } from "./login.service";
import { PageComponent } from "./page/page.component";
import { HashLocationStrategy, PathLocationStrategy } from "@angular/common";
import { LocationStrategy } from "@angular/common";
import { NgModule } from "@angular/core";
import {
	Routes,
	RouterModule,
	PreloadAllModules,
	NoPreloading,
	CanActivate,
} from "@angular/router";
import { FullComponent } from "./layouts/full/full.component";
import { LoginComponent } from "./login/login.component";
import { SignUpComponent } from "./sign-up/sign-up.component";
import { AuthGuard } from "./auth.guard";
import { LandingComponent } from "./landing/landing.component";
import { ActiveComponent } from "./active/active.component";
import { Landing2Component } from "./landing2/landing2.component";

const routes: Routes = [
	{
		path: "demo",
		canActivate: [AuthGuard],
		//loadChildren: () => import('fire-admin').then(m => m.FireAdminModule)
		loadChildren: () =>
			import("ng-fire-admin").then((m) => m.FireAdminModule),
	},
	{ path: "mentalhealth", component: Landing2Component },
	{ path: "land", component: LandingComponent },
	{ path: "active", component: ActiveComponent },
	{
		path: "",
		component: FullComponent,
		children: [
			{
				path: "",
				redirectTo: "dashboard",
				pathMatch: "full",
			},
			{
				path: "",
				loadChildren: () =>
					import("./material-component/material.module").then(
						(m) => m.MaterialComponentsModule
					),
			},

			{
				path: "dashboard",
				loadChildren: () =>
					import("./dashboard/dashboard.module").then(
						(m) => m.DashboardModule
					),
			},
			{
				path: "info",
				loadChildren: () =>
					import("./information/information.module").then(
						(m) => m.informationModule
					),
			},
			{
				path: "user",
				loadChildren: () =>
					import("./UserInfo/userInfo.module").then(
						(m) => m.userInfoModule
					),
			},
			{
				path: "login",
				component: LoginComponent,
			},
			{
				path: "signUp",
				component: SignUpComponent,
			},
			{
				path: "forgotpwd",
				component: ForgotpwdComponent,
			},
			{
				path: ":slug",
				component: PageComponent,
			},
			{ path: "**", component: PageComponent },
		],
	},
	
	{ path: "**", component: FullComponent },
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, {
			useHash: false,
			preloadingStrategy: PreloadAllModules,
		}),
	],
	exports: [RouterModule],
	providers: [
		{ provide: LocationStrategy, useClass: PathLocationStrategy },
		LoginService,
		AuthGuard,
	],
})
export class AppRoutingModule {}
