<div id="top" style="margin: auto;">
    <mat-card style="border-radius: 30px;">
        <mat-card-title style="padding-top: 10%;">Login</mat-card-title>
  <mat-card-content>
    <form [formGroup]="FormData" (ngSubmit)="onSubmit(FormData)">
      <p>
        <mat-form-field>
          <input type="email" matInput placeholder="email" formControlName="Email" required>
        </mat-form-field>
      </p>

      <p>
        <mat-form-field>
          <input type="password" matInput placeholder="Password" autocomplete="on" formControlName="password" required>
        </mat-form-field>
      </p>
      <div class="button" style="text-align: center;">
        <button type="submit" style="border-radius:10px;" class="btn btn-success" >Login</button>
        <br><br>Not Registered? <a [routerLink]="['/signUp']" style="text-decoration: underline;">SignUp</a>
        <br>Forgot password?<a  [routerLink]="['/forgotpwd']" style="text-decoration: underline;">Click Here</a>
      </div>
    </form>
  </mat-card-content>
</mat-card>
</div>