/** @format */

import { AngularFirestore } from "@angular/fire/firestore";
import { AngularFireAuth } from "@angular/fire/auth";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Subject } from "rxjs";

@Injectable({
	providedIn: "root",
})
export class LoginService {
	loginState: any = null;
	nums: number = 1;
	loginSub = new Subject<boolean>();
	userLog = new Subject<any>();
	doctorSub = new Subject<boolean>(); // doctor check
	doctorLog = new Subject<any>();
	roleSub = new Subject<boolean>(); // admin check
	ownerSub = new Subject<boolean>(); // admin check
	vendorSub = new Subject<boolean>(); // admin check
	roleLog = new Subject<any>();
	login = false;
	DoctorData: any;
	RoleData: any;
	signupuser: string;
	fullname: string;
	companyname: string;
	signrole: string;

	constructor(
		private afu: AngularFireAuth,
		private router: Router,
		private firestore: AngularFirestore
	) {
		this.afu.authState.subscribe((login) => {
			this.loginState = login;
			// console.log(this.loginState.emailVerified);

			// tslint:disable-next-line:curly
			if (this.loginState != null) {
				if (this.loginState.emailVerified) {
					this.loginSub.next(true);
					this.userLog.next(this.loginState);
					this.isUserDoctor(this.loginState.uid);
					this.getUserData(this.loginState.uid);
				} else {
					this.loginSub.next(false);
					this.userLog.next(null);
					this.doctorLog.next(null);
					this.doctorSub.next(false);
					this.roleLog.next(null);
					this.roleSub.next(false);
					this.ownerSub.next(false);
					this.vendorSub.next(false);
				}
				// console.log(this.loginState.uid);
			} else {
				this.loginSub.next(false);
				this.userLog.next(null);
				this.doctorLog.next(null);
				this.doctorSub.next(false);
				this.roleLog.next(null);
				this.roleSub.next(false);
				this.ownerSub.next(false);
				this.vendorSub.next(false);
			}
		});
	}

	changeStatus() {
		this.login = false;
		this.loginSub.next(this.login);
		this.loginState = null;
		this.userLog.next(null);
		this.doctorLog.next(null);
		this.doctorSub.next(false);
		this.roleLog.next(null);
		this.roleSub.next(false);
		this.ownerSub.next(false);
		this.vendorSub.next(false);
		this.afu.auth.signOut().then(() => {
			this.router.navigate(["/dashboard"]);
		});
	}

	/******** isUser Doctor check**********/
	isUserDoctor(id: string) {
		this.DoctorData = this.firestore.collection("b2bDoctors").get();

		this.DoctorData.subscribe((ss) => {
			// console.log(ss.docs.length);
			let cnt = ss.docs.length;
			for (let i = 0; i < cnt; i++) {
				// console.log(id);
				// console.log(ss.docs[i].data().uid);
				if (id == ss.docs[i].data().uid) {
					this.doctorSub.next(true);
					this.doctorLog.next(ss.docs[i].data());
					//  console.log(ss.docs[i].data());
					return;
				}
			}
		});
	}

	//isUserOwner(id: string){
	//	let Data = this.firestore.collection("b2bOwnerRegistration").get();
//
//		Data.subscribe((ss) => {
//			// console.log(ss.docs.length);
//			let cnt = ss.docs.length;
//			for (let i = 0; i < cnt; i++) {
//				if(id == ss.docs[i].data().email ){
//				return true;}				
//			}
//		});
//		return false;
//	}

	getUserData(id: string) {
		this.RoleData = this.firestore.collection("users").doc(id).get();

		this.RoleData.subscribe((ss) => {
			if (ss.data() != null) {
				if (ss.data().role == "admin") {
					//  console.log('true');
					//  localStorage.setItem('admin', 'true');
					this.roleSub.next(true);
				}
				if (ss.data().role == "owners") {
					//console.log('true');
					// localStorage.setItem('admin', 'true');
					this.ownerSub.next(true);
				}
				if (ss.data().role == "vendors") {
					//console.log('true');
					// localStorage.setItem('admin', 'true');
					this.vendorSub.next(true);
				}
				this.roleLog.next(ss.data());
			}
		});
	}

	/************* login check  **********/
	loginAcc(data) {
		this.afu.auth
			.signInWithEmailAndPassword(data.Email, data.password)
			.then((user) => {
				this.loginState = user;
				// console.log(this.loginState.user.emailVerified);
				if (this.loginState.user.emailVerified) {
					this.login = true;
					this.loginSub.next(true);
					this.isUserDoctor(this.loginState.user.uid);
					this.getUserData(this.loginState.user.uid);
					this.userLog.next(this.loginState);
				} else {
					window.alert(
						"email not verified verification mail is send, accept and refresh you would be logged in"
					);
					this.afu.auth.currentUser
						.sendEmailVerification()
						.then(function () {
							// Email sent.
						})
						.catch(function (error) {
							// An error happened.
						});
					this.router.navigate(["/login"]);
				}
				this.router.navigate(["/"]);
				return true;
			})
			.catch((error) => {
				console.log(error);
				window.alert(error.message);
				throw error;
			});
		return false;
	}

	signUp(data) {
		this.afu.auth
			.createUserWithEmailAndPassword(data.email, data.password)
			.then((user) => {
				this.afu.auth.currentUser
					.sendEmailVerification()
					.then(function () {
						window.alert("email for verification is send");
						// Email sent.
					})
					.catch(function (error) {
						// An error happened.
					});

				this.loginState = user;
				//  console.log(user.user.uid);
				this.signupuser = user.user.uid;
				this.fullname = data.fname + " " + data.lname;
				this.signrole = data.type;

				this.firestore.collection("users").doc(this.signupuser).set(
					{
						role: "user",
						name: this.fullname,
						email: data.email,
						avatar: null,
						bio: null,
						birthDate: null,
						firstname: data.fname,
						lastname: data.lname,
					},
					{ merge: true }
				);

				// tslint:disable-next-line:one-line
				if (data.type == "Doctor") {
					this.firestore
						.collection("b2bDoctorsRegistration")
						.doc(this.signupuser)
						.set({
							email: data.email,
							active: false,
						});
				} else if (data.type == "owners") {
					this.firestore
						.collection("b2bOwnersRegistration")
						.doc(this.signupuser)
						.set({
							email: data.email,
							active: false,
						});
				} else if (data.type == "vendors") {
					this.firestore
						.collection("b2bVendorsRegistration")
						.doc(this.signupuser)
						.set({
							email: data.email,
							active: false,
						});
				}

				this.login = true;
				this.loginSub.next(true);
				this.userLog.next(this.loginState);
				this.router.navigate(["/login"]);
				return true;
			})
			.catch((error) => {
				console.log(error);
				window.alert(error.message);
				throw error;
			});
		return false;
	}

	getName() {
		console.log("Hello");
		//  console.log(this.loginState.user.displayName);
		return this.loginState.user.displayName;
	}

	resetEmail(email: string) {
		//  console.log(email);
		this.afu.auth
			.sendPasswordResetEmail(email)
			.then(() => window.alert("sent Password Reset Email!"))
			.catch((error) => console.log(error));
	}

	getuid() {
		//  console.log(this.loginState.user.displayName);
		return this.loginState.user.uid;
	}
}
