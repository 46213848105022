import { ChangeDetectorRef, Component, OnInit, ViewChild, OnDestroy, AfterViewInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { MatPaginator, MatTableDataSource } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';

export interface Post {
  id?: string;
  lang: string;
  title: string;
  slug: string;
  date: number; // timestamp
  image?: File|string|Observable<string>|{ path: string|any, url: string|Observable<string> };
  content: string;
  status: PostStatus;
  categories: string[];
  createdAt?: number;
  updatedAt?: number;
  createdBy?: string;
  author?: string|Observable<string>;
  updatedBy?: string;
  translationId?: string;
  //translations?: PostTranslation; // used to store translations on object fetch
  isTranslatable?: boolean;
}

export enum PostStatus {
  Draft = 'draft',
  Published = 'published',
  Trash = 'trash'
}


@Component({
  selector: 'app-page',
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.css']
})
export class PageComponent implements OnInit, OnDestroy, AfterViewInit {

  myArray: Post;
  myCate: any[] = [];
  show = true;
  observableData: Observable<any>;
  searchText: string;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  obs: Observable<any>;
  dataSource: MatTableDataSource<Post>;
  str: string;
  strimage: string;
  data1: any;
  postdata: any;
  data: any;
  slug: any;

  constructor(private firestore: AngularFirestore,
      private storage: AngularFireStorage, private route: ActivatedRoute,
     private changeDetectorRef: ChangeDetectorRef, private router: Router) {
        console.log('hello');
   }
  ngOnInit(): void {

    this.route.params.subscribe(params => {
      this.slug = params['slug']; // (+) converts string 'id' to a number
      console.log(this.slug);
    });


   this.postdata = this.firestore.collection('posts').get();

  this.postdata.subscribe((ss) => {

    for( let i=0 ; i < ss.docs.length;i++)
    {
    let data = ss.docs[i].data();
   // console.log(data);
    if(this.slug == data.slug)
    {
    this.strimage = data.image;
   // this.strimage = this.strimage.slice(6);
    console.log(this.strimage);
        if(this.strimage!=null)
        {
        let storageRef = this.storage.ref(this.strimage);
          storageRef.getDownloadURL().subscribe(url => {
            //console.log(url);
          data.image = url;
        });
        }
    this.str = data.createdBy;
     // str="'"+str+"'";
    // console.log(this.str);

            this.firestore
            .collection('users')
            .doc(this.str)
            .get()
            .subscribe((ss) => {
           // console.log(ss.data());
            this.data1 = ss.data();
            data.createdBy = this.data1.firstName + ' ' + this.data1.lastName;
           // console.log(1 + 2);
            this.myArray = data;
            this.show = false;
           // console.log(data);
          //  this.show = false;
           // this.dataSource = new MatTableDataSource<any>(this.myArray);
          //  console.log('hello');
           // this.dataSource.paginator = this.paginator;
            // this.obs = this.dataSource.connect();
          });
        return;
    }
  }

  });


  this.firestore
  .collection('categories')
  .get()
  .subscribe((ss) => {
    ss.docs.forEach((doc) => {
      let data = doc.data();
      let id = doc.id;
      this.myCate.push({ data , id});
      //console.log(this.myCate);
    });
  });



  }



  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }


  filterCategory(event) {
    // tslint:disable-next-line:curly
    if (event !== 'All')
    this.dataSource.filter = event
    // tslint:disable-next-line:curly
    else
    this.dataSource.filter = ''
  }

  gotoDetail(slug: string): void {
    this.router.navigate(['/', slug]);
  }

  ngAfterViewInit() {
    // Hack: Scrolls to top of Page after page view initialized
    let top = document.getElementById('top');
    if (top !== null) {
      top.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'start'});
      top = null;
    }
  }

  ngOnDestroy() {
    if (this.dataSource) {
      this.dataSource.disconnect();
    }
  }

}
