import { Injectable } from '@angular/core';

export interface Menu {
  state: string;
  name: string;
  type: string;
  icon: string;
}

const MENUITEMS = [
  { state: '/dashboard', name: 'Dashboard', type: 'link', icon: 'av_timer' },
  {state:'/user/appointment',name:'Appointment',type:'link', icon:'alarm'},
  {state:'/user/licence',name:'Licence',type:'link', icon:'alarm'},
  
  {state:'/demo',name:'Blog updates',type:'link', icon:'alarm'},
];

@Injectable()
export class MenuItems {
  getMenuitem(): Menu[] {
    return MENUITEMS;
  }
}
