<!-- @format -->

<div class="main-container">
	<mat-toolbar color="primary" class="topbar telative">
		<div class="navbar-header">
			<a class="navbar-brand" href="index.html">
				<b>
					<img
						src="assets/images/logo_4.png"
						style="height: auto; max-height: 45px"
						class="light-logo"
						alt="homepage"
					/>
					<img
						src="assets/images/logo_1.png"
						style="height: auto; max-height: 70px"
						class="light-logo"
						alt="homepage"
					/>
				</b>
			</a>
		</div>
		<button
			mat-icon-button
			*ngIf="login"
			(click)="snav.toggle()"
			[fxShow.xs]="true"
			[fxShow]="false"
			value="sidebarclosed"
		>
			<mat-icon>menu</mat-icon>
		</button>
		<span fxFlex></span>
		<span fxFlex.xs></span>
		<app-header></app-header>
	</mat-toolbar>
	<mat-sidenav-container
		class="example-sidenav-container"
		[style.marginTop.px]="mobileQuery.matches ? 0 : 0"
	>
		<mat-sidenav
			#snav
			id="snav"
			class="dark-sidebar pl-xs"
			[mode]="mobileQuery.matches ? 'side' : 'over'"
			fixedTopGap="0"
			[opened]="login"
			[disableClose]="mobileQuery.matches"
		>
			<app-sidebar></app-sidebar>
		</mat-sidenav>
		<mat-sidenav-content
			class="page-wrapper"
			style="background: linear-gradient(to right, #1e88e5, #f2fcfe)"
		>
			<div class="page-content" style="margin: 0">
				<router-outlet><app-spinner></app-spinner></router-outlet>
			</div>
			<div class="page-content">
				<app-footer></app-footer>
			</div>
		</mat-sidenav-content>
	</mat-sidenav-container>
</div>
