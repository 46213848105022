import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import * as firebase from 'firebase';
import { LoginService } from '../login.service';


@Component({
  selector: 'app-forgotpwd',
  templateUrl: './forgotpwd.component.html',
  styleUrls: ['./forgotpwd.component.css'],
  providers: [LoginService]
})
export class ForgotpwdComponent implements OnInit {

  hide = true;
  constructor(private router: Router, public logSer: LoginService) { }
 @ViewChild('f') form: NgForm;
 status: boolean = true;
 text= '';
 mail="";
  ngOnInit(): void {
  }
  
send(){
let  auth = firebase.auth();
let emailAddress = this.mail;

auth.sendPasswordResetEmail(emailAddress).then(function() {
  // Email sent.
}).catch(function(error) {
  // An error happened.
});
}

}
