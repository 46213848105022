<div [fxShow]=true [fxShow.xs]=false *ngIf="!login">
<button mat-button (click)="goToExpert()">Experts</button>
<button mat-button [routerLink]="['/dashboard/services']">services</button>
<button mat-button (click)="goToCalc()">Calculators</button>
<button mat-button [matMenuTriggerFor]="option2">Products</button>
<button mat-button [routerLink]="['/dashboard/blog']">Blog</button>
<button mat-button (click)="goToTest()">Covid Testing</button>
<button mat-button (click)="goToHealth()">Mental Health</button>
<button mat-button (click)="goToCareer()">Career</button>
<button mat-button (click)="button('Sign in')"><mat-icon class="mr">login</mat-icon>Login</button>
<mat-menu #option2="matMenu">
    <a href="https://play.google.com/store/apps/details?id=nowcare.com.nowcare4u" target="_blank"><button mat-menu-item style="width: fit-content;margin: auto;"><img src="../../assets/images/icons8-android-os-52.png"></button></a>
    <a href="https://apps.apple.com/app/id1531364454" target="_blank"><button mat-menu-item style="width: fit-content;margin: auto;"><img src="../../assets/images/icons8-apple-logo-52.png"></button></a>
</mat-menu>

</div>
<div [fxShow]=true [fxShow.xs]=false *ngIf="login">
  <button mat-button (click)="goToExpert()">Experts</button>
  <button mat-button [routerLink]="['/dashboard/services']">services</button>
  <button mat-button (click)="goToCalc()">Calculators</button>
  <button mat-button [matMenuTriggerFor]="option2">Products</button>
  <button mat-button [routerLink]="['/dashboard/blog']">Blog</button>
  <button mat-button (click)="goToTest()">Covid Testing</button>
  <button mat-button (click)="goToCareer()">Mental Health</button>
  <button mat-button (click)="goToCareer()">Career</button>
  <button mat-button (click)="onLogout();">   <mat-icon class="mr">logout</mat-icon>Logout</button>
  

  
  <mat-menu #option2="matMenu">
    <a href="https://play.google.com/store/apps/details?id=nowcare.com.nowcare4u" target="_blank"><button mat-menu-item style="width: fit-content;margin: auto;"><img src="../../assets/images/icons8-android-os-52.png"></button></a>
    <a href="https://apps.apple.com/app/id1531364454" target="_blank"><button mat-menu-item style="width: fit-content;margin: auto;"><img src="../../assets/images/icons8-apple-logo-52.png"></button></a>
</mat-menu>

  </div>
<div [fxShow]=false   [fxShow.xs]=true *ngIf="!login">
  <button mat-icon-button [matMenuTriggerFor]="dropMenu">
    <mat-icon>more_vert</mat-icon>
  </button>
  <mat-menu #dropMenu="matMenu">
    <button mat-menu-item (click)="goToExpert()">Experts</button>
    <button mat-menu-item [routerLink]="['/dashboard/services']">services</button>
    <button mat-menu-item (click)="goToCalc()">Calculators</button>
    <button mat-menu-item [matMenuTriggerFor]="option2" xPosition="before">Products</button>
    <button mat-menu-item [routerLink]="['/dashboard/blog']">Blog</button>
    <button mat-menu-item (click)="goToTest()">Covid Testing</button>
    <button mat-menu-item (click)="goToCareer()">Mental Health</button>
    <button mat-menu-item (click)="goToCareer()">Career</button>
    <button mat-menu-item (click)="button('Sign in')"><mat-icon class="mr">login</mat-icon>Login</button>
    <mat-menu #option2="matMenu">
        <a href="https://play.google.com/store/apps/details?id=nowcare.com.nowcare4u" target="_blank"><button mat-button style="width: fit-content;margin: auto;"><img src="../../assets/images/icons8-android-os-52.png"></button></a>
        <a href="https://apps.apple.com/app/id1531364454" target="_blank"><button mat-button style="width: fit-content;margin: auto;"><img src="../../assets/images/icons8-apple-logo-52.png"></button></a>
    </mat-menu>
  </mat-menu>
  </div>

<div [fxShow]=false   [fxShow.xs]=true *ngIf="login">
    <button mat-icon-button [matMenuTriggerFor]="dropMenu">
      <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu #dropMenu="matMenu">
      <button mat-menu-item (click)="goToExpert()">Experts</button>
      <button mat-menu-item [routerLink]="['/dashboard/services']">services</button>
      <button mat-menu-item (click)="goToCalc()">Calculators</button>
      <button mat-menu-item [matMenuTriggerFor]="option2" xPosition="before">Products</button>
      <button mat-menu-item [routerLink]="['/dashboard/blog']">Blog</button>
      <button mat-menu-item (click)="goToTest()">Covid Testing</button>
      <button mat-menu-item (click)="goToCareer()">Mental Health</button>
      <button mat-menu-item (click)="goToCareer()">Career</button>
      <button mat-menu-item (click)="onLogout();">   <mat-icon class="mr">logout</mat-icon>Logout</button>
      <mat-menu #option2="matMenu">
          <a href="https://play.google.com/store/apps/details?id=nowcare.com.nowcare4u" target="_blank"><button mat-button style="width: fit-content;margin: auto;"><img src="../../assets/images/icons8-android-os-52.png"></button></a>
          <a href="https://apps.apple.com/app/id1531364454" target="_blank"><button mat-button style="width: fit-content;margin: auto;"><img src="../../assets/images/icons8-apple-logo-52.png"></button></a>
      </mat-menu>
    </mat-menu>
    </div>
  
  