<!-- <router-outlet></router-outlet> -->
<div
  style="
    margin: 0;
    padding: 0;
    width: 100vw;
    min-height: 100vh;
    background-image: linear-gradient(to right, #1e88e5, white);
  "
>
<a [routerLink]="['/dashboard']" style="position: fixed; right:10px;" class="btn">Go Back</a>
  <div class="oppt flex ">
    <button (click)="fil(all)" class="btn">All {{pro.length}}</button>
    <button (click)="fil(active)" class="btn">Active {{act}}</button>
    <button (click)="fil(inactive)" class="btn">Inactive {{inact}}</button>
  </div>
  <table class="table" style="width: 80vw; margin: 10vh 5vw">
    <thead style="background-color:#ade0dd; text-align: center;">
      <tr>
        <td scope="col-1">#</td>
        <td scope="col-2">id</td>
        <td scope="col-4">Name</td>
        <td scope="col-5">Email</td>
      </tr>
    </thead>
    <tr
      *ngFor="let item of pro; let i = index ;"
      [ngStyle]="{ 'background-color': dor(item.program.InUse) }"
    >
      <ng-template [ngIf]="func(item.program.InUse)">
        <th scope="row" style="text-align: center">{{ i + 1 }}</th>
        <td style="text-align: center">{{ item.program.No }}</td>
        <td style="text-align: center">{{ item.program.Name }}</td>
        <td style="text-align: center">{{ item.program.Email }}</td>
      </ng-template>
    </tr>
  </table>
</div>
