import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from '../login.service';
import { SimpleSmoothScrollService, ISimpleSmoothScrollOption } from 'ng2-simple-smooth-scroll';

@Component({
  selector: 'app-menu-bar',
  templateUrl: './menu-bar.component.html',
  styleUrls: ['./menu-bar.component.css'],
  providers: [LoginService]
})
export class MenuBarComponent implements OnInit {
  login: boolean= false;
  dlogin: boolean= false;
  constructor(private router: Router, private logSer: LoginService, private smooth: SimpleSmoothScrollService) {
    
   }
    button(data){
    if (data == 'Sign in'){
      this.router.navigate(['/login']);
    }
  }
    onLogout(){
    this.logSer.changeStatus();
    }
  ngOnInit(): void {
    this.smooth.smoothScrollToAnchor();
    this.logSer.loginSub.subscribe(data => {
      this.login = data;
    });
    this.logSer.doctorSub.subscribe(data => {
      this.dlogin = data;
    });
  }
  goToExpert(){
    console.log('click');
    this.router.navigateByUrl('/dashboard#expert');
 }
 goToCalc(){
  console.log('click');
  this.router.navigate(['../../dashboard'], { fragment: 'calculator' } );
}

goToTest(){
  console.log('click');
  this.router.navigate(['/dashboard'], { fragment: 'Testing' } );
}

goToCareer(){
  console.log('click');
  this.router.navigate(['/dashboard'], { fragment: 'Career' } );
}
goToHealth(){
  console.log('click');
  //this.router.navigate(['/dashboard'], { fragment: 'mentalhealth' } );
  window.location.href = "../../mentalhealth/index.html";
}

}
