<!-- @format -->
<div class="disappear" style="height: 0px; overflow: hidden">
	<h1>
		We're making a difference...
		<mark>NowCare4u Mental Health Program </mark>
	</h1>
	<h2>
		The NowCare4u Experience<mark>NowCare4u Mental Health Product </mark>
	</h2>
	<h2>Try Our Self-Help Courses<mark>NowCare4u Career Counselling </mark></h2>
	<h2>
		A Programme of Self-Care Tools<mark
			>NowCare4u Predictive Analysis
		</mark>
	</h2>
	<h2>How do we work?<mark>Mental education </mark></h2>
	<h2>The Scientific Basis for NowCare4u<mark>Brain Training </mark></h2>
	<h2>A Happier Life Awaits You<mark>Life Training </mark></h2>
	<h2>Get a Taste of Our Activities<mark>MindFulness </mark></h2>
	<h2><mark>Life Science </mark></h2>
	<h2><mark>Chat online</mark></h2>
	<h3>
		Get therapy in your pocket...<mark
			>Online Counselling Emotional Wellness</mark
		>
	</h3>
	<h3>...to create lasting change<mark>Self Improvement</mark></h3>
	<h3>Personalised Plan<mark>Emotional Wellness</mark></h3>
	<h3>Self-Care Goals<mark>anonymously</mark></h3>
	<h3>Recommended Activities <mark>career</mark></h3>
	<h3>Insightful Tracker<mark>relationship</mark></h3>
	<h3>InnerHour Resources<mark>parenting</mark></h3>
	<h3>Relief Chatbot<mark>counselors</mark></h3>
	<h3>Cognitive Behaviour Therapy<mark>advice</mark></h3>
	<h3>Positive Psychology<mark>psychologists</mark></h3>
	<h3>Mindfulness<mark>relieving</mark></h3>
	<h4><mark>stress</mark></h4>
	<h4>Build a good life for yourself with NowCare4u<mark>anxiety</mark></h4>
	<h4>
		Pick a date & time slot for your therapy session<mark>depression</mark>
	</h4>
	<h4>Available Slots<mark>Counselling</mark></h4>
	<h4>Pick a Package<mark>Coach</mark></h4>
	<h4>
		Mental Health Tips NowCare4u<mark
			>Counselling Emotional Wellness Coach</mark
		>
	</h4>
	<h5>Control Stress<mark>Self-Care</mark></h5>
	<h5>Conquer Anxiety<mark>NowCare4u app helps</mark></h5>
	<h5>Sleep Soundly<mark>NowCare4u App Store</mark></h5>
	<h5>Manage Anger<mark>NowCare4u support</mark></h5>
	<h5>Find Happiness<mark>Depression</mark></h5>
	<h5>
		As the requested session is due soon, we will have to confirm the
		therapist's availability. In case the therapist chooses not to accept
		the session, your complete payment will be refunded back to y...<mark
			>live happier</mark
		>
	</h5>
	<h6>Library<mark>live</mark></h6>
	<h6>Therapy<mark>Stress</mark></h6>
	<h6>Areas<mark>Find time</mark></h6>
	<h6>Partnerships<mark>find</mark></h6>
	<h6>Try Our Activities<mark>Anxiety</mark></h6>
	<h6>About Us<mark>Sleep</mark></h6>
	<h6>Help<mark>happier life</mark></h6>
	<h6>LIVE THERAPY<mark>NowCare4u time management </mark></h6>
	<h6>TELE THERAPY<mark></mark></h6>
	<h6>CHAT THERAPY<mark></mark></h6>
</div>


<router-outlet><app-spinner></app-spinner></router-outlet>
