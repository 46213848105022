<!DOCTYPE html>
<html lang="en">
	<head>
		<meta charset="utf-8" />
		<meta http-equiv="X-UA-Compatible" content="IE=edge" />
		<meta name="viewport" content="width=device-width, initial-scale=1" />
		<title>​NowCare4U</title>
		<link
			href="https://fonts.googleapis.com/css?family=Fira+Sans:400,400i,500,700"
			rel="stylesheet"
		/>
		<!-- <link rel="stylesheet" href="dist/css/style.css" /> -->
		<script src="https://unpkg.com/scrollreveal@4.0.0/dist/scrollreveal.min.js"></script>
	</head>
	<body class="is-boxed has-animations" style="height: 100vh !important">
		<a
			style="position: fixed; top: 2vh; left: 2vw; z-index: 11"
			class="btn btn-dark"
			href=""
		>
			Home
		</a>
		<div class="body-wrap boxed-container">
			<header class="site-header">
				<div class="container">
					<div class="site-header-large-bg"><span></span></div>
					<div class="site-header-inner">
						<div class="brand header-brand">
							<h1 class="m-0">
								<a href="#">
									<svg
										width="32"
										height="32"
										viewBox="0 0 32 32"
										xmlns="http://www.w3.org/2000/svg"
										xmlns:xlink="http://www.w3.org/1999/xlink"
									>
										<title>Holly</title>
										<defs>
											<linearGradient
												x1="50%"
												y1="0%"
												x2="50%"
												y2="100%"
												id="logo-b"
											>
												<stop
													stop-color="#A8A3F6"
													offset="0%"
												/>
												<stop
													stop-color="#DAD8FF"
													offset="100%"
												/>
											</linearGradient>
											<path
												d="M27.098 7.53l-9.441 9.44a8.084 8.084 0 0 0-.625.702 8 8 0 0 0 12.173 10.377l9.24-9.24A16.02 16.02 0 0 1 39 23c0 8.837-7.163 16-16 16S7 31.837 7 23 14.163 7 23 7c1.416 0 2.79.184 4.098.53z"
												id="logo-a"
											/>
											<linearGradient
												x1="50%"
												y1="0%"
												x2="50%"
												y2="100%"
												id="logo-d"
											>
												<stop
													stop-color="#DAD8FF"
													offset="0%"
												/>
												<stop
													stop-color="#857DF3"
													offset="100%"
												/>
											</linearGradient>
											<path
												d="M27.098 7.53l-9.441 9.44a8.084 8.084 0 0 0-.625.702 8 8 0 0 0 12.173 10.377l9.24-9.24A16.02 16.02 0 0 1 39 23c0 8.837-7.163 16-16 16S7 31.837 7 23 14.163 7 23 7c1.416 0 2.79.184 4.098.53z"
												id="logo-c"
											/>
										</defs>
										<g
											transform="translate(-7 -7)"
											fill="none"
											fill-rule="evenodd"
										>
											<image
												transform="scale(-1 1) rotate(45 0 -32.527)"
												xlink:href="https://i.ibb.co/k04zjth/Whats-App-Image-2021-02-20-at-19-00-12.jpg"
											/>
										</g>
									</svg>
								</a>
							</h1>
						</div>
					</div>
				</div>
			</header>

			<main>
				<section class="hero">
					<div class="container">
						<div
							style="margin-top: -13%; margin-left: 10%; font-family: cursive; font-size: 2rem;"
							class="hero-title head2 h2-mobile is-revealing"
						>
							You Are Not Alone

							<br /> <div style="margin-top:-2%;color:rgb(238, 66, 66); font-size: 2.8rem; margin-left: -4%;">We are there for you</div> 
						</div>
						<div class="hero-inner">
							<div class="hero-copy">
								<div class="container-sm">
									<p
										class="hero-paragraph is-revealing"
										style="
											margin-top: -10%;
											color: rgb(46, 46, 46);
										"
									>
										We wish to share some statistics on mental health across the
										country: This report revealed in 2017,
										one among every seven people in India
										had a mental disorder, ranging from mild
										to severe. The proportional contribution
										of mental disorders to the total disease
										burden in India almost doubled from 1990
										to 2017.
									</p>
									<div
										class="h3"
										style="
											text-align: center;
											font-family: cursive;
											color: rgb(238, 66, 66);
											margin-bottom: 5%;
										"
									>
										Get To Know Yourself <br />
										Register !!!
									</div>
									<div style="width: 100%">
										<div
											class="hero-form newsletter-form field field-grouped is-revealing"
										>
											<div
												class="control control-expanded"
											>
												<input
													class="input name"
													type="text"
													name="nam"
													placeholder="Your Name&hellip;"
													#textbox3="ngModel"
													[(ngModel)]="nam"
													nam="textbox3"
												/>
											</div>
										</div>
										<div
										class="hero-form newsletter-form field field-grouped is-revealing"
									>

											<div
												class="control control-expanded"
											>
												<input
													class="input phon"
													type="text"
													#textbox2="ngModel"
													[(ngModel)]="phone"
													name="textbox2"
													placeholder="Your Phone&hellip;"
												/>
											</div>
										</div>
										<div
											class="hero-form newsletter-form field field-grouped is-revealing"
										>
										
											<div
												class="control control-expanded"
											>
												<input
													class="input emai"
													type="email"
													name="email"
													placeholder="Your Email&hellip;"
													#textbox1="ngModel"
													[(ngModel)]="email"
													name="textbox1"
												/>
											</div>
										</div>
										<div
											class="hero-form newsletter-form field field-grouped is-revealing"
										>

											<div class="control" *ngIf="nam!=='' && phone!=='' && email!==''">
												<button
													class="button bp button-primary button-block button-shadow"
													(click)="clicked()"
													style="min-width: 100%!important;"
													>Get early access</button
												>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="hero-illustration">
								<div
									class="hero-squares hero-squares-1 is-revealing"
								>
									<svg
										width="124"
										height="64"
										viewBox="0 0 124 64"
										xmlns="http://www.w3.org/2000/svg"
									>
										<defs>
											<linearGradient
												x1="0%"
												y1="50%"
												x2="114.418%"
												y2="50%"
												id="squares-1-a"
											>
												<stop
													stop-color="#6EFACC"
													offset="0%"
												/>
												<stop
													stop-color="#6EFACC"
													stop-opacity="0"
													offset="100%"
												/>
											</linearGradient>
										</defs>
										<path
											d="M0 0h4v4H0V0zm0 12h4v4H0v-4zm0 12h4v4H0v-4zm0 12h4v4H0v-4zm0 12h4v4H0v-4zm0 12h4v4H0v-4zM12 0h4v4h-4V0zm0 12h4v4h-4v-4zm0 12h4v4h-4v-4zm0 12h4v4h-4v-4zm0 12h4v4h-4v-4zm0 12h4v4h-4v-4zM24 0h4v4h-4V0zm0 12h4v4h-4v-4zm0 12h4v4h-4v-4zm0 12h4v4h-4v-4zm0 12h4v4h-4v-4zm0 12h4v4h-4v-4zM36 0h4v4h-4V0zm0 12h4v4h-4v-4zm0 12h4v4h-4v-4zm0 12h4v4h-4v-4zm0 12h4v4h-4v-4zm0 12h4v4h-4v-4zM48 0h4v4h-4V0zm0 12h4v4h-4v-4zm0 12h4v4h-4v-4zm0 12h4v4h-4v-4zm0 12h4v4h-4v-4zm0 12h4v4h-4v-4zM60 0h4v4h-4V0zm0 12h4v4h-4v-4zm0 12h4v4h-4v-4zm0 12h4v4h-4v-4zm0 12h4v4h-4v-4zm0 12h4v4h-4v-4zM72 0h4v4h-4V0zm0 12h4v4h-4v-4zm0 12h4v4h-4v-4zm0 12h4v4h-4v-4zm0 12h4v4h-4v-4zm0 12h4v4h-4v-4zM84 0h4v4h-4V0zm0 12h4v4h-4v-4zm0 12h4v4h-4v-4zm0 12h4v4h-4v-4zm0 12h4v4h-4v-4zm0 12h4v4h-4v-4zM96 0h4v4h-4V0zm0 12h4v4h-4v-4zm0 12h4v4h-4v-4zm0 12h4v4h-4v-4zm0 12h4v4h-4v-4zm0 12h4v4h-4v-4zm12-60h4v4h-4V0zm0 12h4v4h-4v-4zm0 12h4v4h-4v-4zm0 12h4v4h-4v-4zm0 12h4v4h-4v-4zm0 12h4v4h-4v-4zm12-60h4v4h-4V0zm0 12h4v4h-4v-4zm0 12h4v4h-4v-4zm0 12h4v4h-4v-4zm0 12h4v4h-4v-4zm0 12h4v4h-4v-4z"
											fill="url(#squares-1-a)"
											fill-rule="evenodd"
										/>
									</svg>
								</div>
								<div
									class="hero-squares hero-squares-2 is-revealing"
								>
									<svg
										width="64"
										height="88"
										viewBox="0 0 64 88"
										xmlns="http://www.w3.org/2000/svg"
									>
										<defs>
											<linearGradient
												x1="0%"
												y1="50%"
												x2="114.418%"
												y2="50%"
												id="squares-2-a"
											>
												<stop
													stop-color="#6EFACC"
													offset="0%"
												/>
												<stop
													stop-color="#6EFACC"
													stop-opacity="0"
													offset="100%"
												/>
											</linearGradient>
										</defs>
										<path
											d="M80 574h4v4h-4v-4zm0 12h4v4h-4v-4zm0 12h4v4h-4v-4zm0 12h4v4h-4v-4zm0 12h4v4h-4v-4zm0 12h4v4h-4v-4zm12-60h4v4h-4v-4zm0 12h4v4h-4v-4zm0 12h4v4h-4v-4zm0 12h4v4h-4v-4zm0 12h4v4h-4v-4zm0 12h4v4h-4v-4zm12-60h4v4h-4v-4zm0 12h4v4h-4v-4zm0 12h4v4h-4v-4zm0 12h4v4h-4v-4zm0 12h4v4h-4v-4zm0 12h4v4h-4v-4zm12-60h4v4h-4v-4zm0 12h4v4h-4v-4zm0 12h4v4h-4v-4zm0 12h4v4h-4v-4zm0 12h4v4h-4v-4zm0 12h4v4h-4v-4zm12-60h4v4h-4v-4zm0 12h4v4h-4v-4zm0 12h4v4h-4v-4zm0 12h4v4h-4v-4zm0 12h4v4h-4v-4zm0 12h4v4h-4v-4zm12-60h4v4h-4v-4zm0 12h4v4h-4v-4zm0 12h4v4h-4v-4zm0 12h4v4h-4v-4zm0 12h4v4h-4v-4zm0 12h4v4h-4v-4zm12-60h4v4h-4v-4zm0 12h4v4h-4v-4zm0 12h4v4h-4v-4zm0 12h4v4h-4v-4zm0 12h4v4h-4v-4zm0 12h4v4h-4v-4zm12-60h4v4h-4v-4zm0 12h4v4h-4v-4zm0 12h4v4h-4v-4zm0 12h4v4h-4v-4zm0 12h4v4h-4v-4zm0 12h4v4h-4v-4z"
											transform="rotate(90 359 279)"
											fill="url(#squares-2-a)"
											fill-rule="evenodd"
										/>
									</svg>
								</div>
								<div class="hero-ball hero-ball-1 is-revealing">
									<svg
										width="400"
										height="400"
										viewBox="0 0 400 400"
										xmlns="http://www.w3.org/2000/svg"
									>
										<defs>
											<radialGradient
												cx="56.15%"
												cy="27.43%"
												fx="56.15%"
												fy="27.43%"
												r="57.526%"
												gradientTransform="matrix(.5626 -.82673 .8022 .54591 .026 .589)"
												id="ball-1-a"
											>
												<stop
													stop-color="#F8F7FF"
													offset="0%"
												/>
												<stop
													stop-color="#DAD8FF"
													offset="34.827%"
												/>
												<stop
													stop-color="#9B95F3"
													offset="100%"
												/>
											</radialGradient>
											<filter
												x="-500%"
												y="-500%"
												width="1000%"
												height="1000%"
												filterUnits="objectBoundingBox"
												id="dropshadow-ball-1"
											>
												<feOffset
													dx="24"
													dy="24"
													in="SourceAlpha"
													result="shadowOffsetOuter"
												/>
												<feGaussianBlur
													stdDeviation="24"
													in="shadowOffsetOuter"
													result="shadowBlurOuter"
												/>
												<feColorMatrix
													values="0 0 0 0 0.10 0 0 0 0 0.17 0 0 0 0 0.21 0 0 0 0.22 0"
													in="shadowBlurOuter"
												/>
											</filter>
										</defs>
										<circle
											cx="200"
											cy="200"
											r="200"
											fill="#FFF"
											fill-rule="evenodd"
											style="
												filter: url(#dropshadow-ball-1);
											"
										/>
										<circle
											cx="200"
											cy="200"
											r="200"
											fill="url(#ball-1-a)"
											fill-rule="evenodd"
										/>
									</svg>
								</div>
								<div class="hero-ball hero-ball-2 is-revealing">
									<svg
										width="200"
										height="200"
										viewBox="0 0 200 200"
										xmlns="http://www.w3.org/2000/svg"
									>
										<defs>
											<radialGradient
												cx="56.15%"
												cy="27.43%"
												fx="56.15%"
												fy="27.43%"
												r="57.526%"
												gradientTransform="matrix(.5626 -.82673 .8022 .54591 .026 .589)"
												id="ball-2-a"
											>
												<stop
													stop-color="#F8F7FF"
													offset="0%"
												/>
												<stop
													stop-color="#DAD8FF"
													offset="34.827%"
												/>
												<stop
													stop-color="#9B95F3"
													offset="100%"
												/>
											</radialGradient>
											<filter
												x="-500%"
												y="-500%"
												width="1000%"
												height="1000%"
												filterUnits="objectBoundingBox"
												id="dropshadow-ball-2"
											>
												<feOffset
													dx="24"
													dy="24"
													in="SourceAlpha"
													result="shadowOffsetOuter"
												/>
												<feGaussianBlur
													stdDeviation="24"
													in="shadowOffsetOuter"
													result="shadowBlurOuter"
												/>
												<feColorMatrix
													values="0 0 0 0 0.10 0 0 0 0 0.17 0 0 0 0 0.21 0 0 0 0.22 0"
													in="shadowBlurOuter"
												/>
											</filter>
										</defs>
										<circle
											cx="100"
											cy="100"
											r="100"
											fill="#FFF"
											fill-rule="evenodd"
											style="
												filter: url(#dropshadow-ball-2);
											"
										/>
										<circle
											cx="100"
											cy="100"
											r="100"
											fill="url(#ball-2-a)"
											fill-rule="evenodd"
										/>
									</svg>
								</div>
								<div
									class="hero-illustration-browser is-revealing"
								>
									<svg
										width="800"
										height="450"
										viewBox="0 0 800 450"
										xmlns="http://www.w3.org/2000/svg"
									>
										<defs>
											<linearGradient
												x1="50%"
												y1="0%"
												x2="50%"
												y2="100%"
												id="browser-a"
											>
												<stop
													stop-color="#F89595"
													offset="0%"
												/>
												<stop
													stop-color="#EF5C5C"
													offset="100%"
												/>
											</linearGradient>
											<linearGradient
												x1="50%"
												y1="0%"
												x2="50%"
												y2="100%"
												id="browser-b"
											>
												<stop
													stop-color="#FFDFB0"
													offset="0%"
												/>
												<stop
													stop-color="#FFBB78"
													offset="100%"
												/>
											</linearGradient>
											<linearGradient
												x1="50%"
												y1="0%"
												x2="50%"
												y2="100%"
												id="browser-c"
											>
												<stop
													stop-color="#83E78D"
													offset="0%"
												/>
												<stop
													stop-color="#4BCA55"
													offset="100%"
												/>
											</linearGradient>
											<filter
												x="-30%"
												y="-42.9%"
												width="184%"
												height="220%"
												filterUnits="objectBoundingBox"
												id="browser-d"
											>
												<feOffset
													dx="24"
													dy="24"
													in="SourceAlpha"
													result="shadowOffsetOuter1"
												/>
												<feGaussianBlur
													stdDeviation="24"
													in="shadowOffsetOuter1"
													result="shadowBlurOuter1"
												/>
												<feColorMatrix
													values="0 0 0 0 0.866666667 0 0 0 0 0.905882353 0 0 0 0 0.937254902 0 0 0 0.56 0"
													in="shadowBlurOuter1"
													result="shadowMatrixOuter1"
												/>
												<feMerge>
													<feMergeNode
														in="shadowMatrixOuter1"
													/>
													<feMergeNode
														in="SourceGraphic"
													/>
												</feMerge>
											</filter>
											<linearGradient
												x1="19.946%"
												y1="72.147%"
												x2="73.772%"
												y2="18.374%"
												id="browser-e"
											>
												<stop
													stop-color="#F8F7FF"
													offset="0%"
												/>
												<stop
													stop-color="#DAD8FF"
													offset="100%"
												/>
											</linearGradient>
											<linearGradient
												x1="50%"
												y1="0%"
												x2="50%"
												y2="100%"
												id="browser-f"
											>
												<stop
													stop-color="#DAD8FF"
													offset="0%"
												/>
												<stop
													stop-color="#857DF3"
													offset="100%"
												/>
											</linearGradient>
											<linearGradient
												x1="50%"
												y1="0%"
												x2="50%"
												y2="100%"
												id="browser-g"
											>
												<stop
													stop-color="#DAD8FF"
													offset="0%"
												/>
												<stop
													stop-color="#CDCAFF"
													offset="100%"
												/>
											</linearGradient>
											<linearGradient
												x1="94.808%"
												y1="-15.701%"
												x2="6.924%"
												y2="82.567%"
												id="browser-h"
											>
												<stop
													stop-color="#5DFBD7"
													stop-opacity="0"
													offset="0%"
												/>
												<stop
													stop-color="#5DFBD7"
													offset="100%"
												/>
											</linearGradient>
											<linearGradient
												x1="1.733%"
												y1="-10.509%"
												y2="77.375%"
												id="browser-i"
											>
												<stop
													stop-color="#6EFACC"
													stop-opacity="0"
													offset="0%"
												/>
												<stop
													stop-color="#6EFACC"
													offset="100%"
												/>
											</linearGradient>
											<linearGradient
												x1="92.458%"
												y1="5.866%"
												x2="0%"
												y2="100%"
												id="browser-j"
											>
												<stop
													stop-color="#5DFBD7"
													offset="0%"
												/>
												<stop
													stop-color="#5DFBD7"
													stop-opacity="0"
													offset="100%"
												/>
											</linearGradient>
											<linearGradient
												x1="92.458%"
												y1="5.866%"
												x2="0%"
												y2="100%"
												id="browser-k"
											>
												<stop
													stop-color="#5DFBD7"
													stop-opacity="0"
													offset="0%"
												/>
												<stop
													stop-color="#5DFBD7"
													stop-opacity=".513"
													offset="48.724%"
												/>
												<stop
													stop-color="#5DFBD7"
													stop-opacity="0"
													offset="100%"
												/>
											</linearGradient>
											<linearGradient
												x1="92.458%"
												y1="5.866%"
												x2="0%"
												y2="100%"
												id="browser-l"
											>
												<stop
													stop-color="#5DFBD7"
													stop-opacity="0"
													offset="0%"
												/>
												<stop
													stop-color="#5DFBD7"
													stop-opacity=".513"
													offset="47.494%"
												/>
												<stop
													stop-color="#5DFBD7"
													stop-opacity="0"
													offset="100%"
												/>
											</linearGradient>
											<filter
												x="-23.1%"
												y="-21.8%"
												width="192.3%"
												height="187.3%"
												filterUnits="objectBoundingBox"
												id="browser-m"
											>
												<feOffset
													dx="24"
													dy="24"
													in="SourceAlpha"
													result="shadowOffsetOuter1"
												/>
												<feGaussianBlur
													stdDeviation="12"
													in="shadowOffsetOuter1"
													result="shadowBlurOuter1"
												/>
												<feColorMatrix
													values="0 0 0 0 0.866666667 0 0 0 0 0.905882353 0 0 0 0 0.937254902 0 0 0 0.56 0"
													in="shadowBlurOuter1"
													result="shadowMatrixOuter1"
												/>
												<feMerge>
													<feMergeNode
														in="shadowMatrixOuter1"
													/>
													<feMergeNode
														in="SourceGraphic"
													/>
												</feMerge>
											</filter>
											<linearGradient
												x1="100%"
												y1="50%"
												x2="-57.904%"
												y2="50%"
												id="browser-n"
											>
												<stop
													stop-color="#DAD8FF"
													offset="0%"
												/>
												<stop
													stop-color="#857DF3"
													offset="100%"
												/>
											</linearGradient>
											<filter
												x="-500%"
												y="-500%"
												width="1000%"
												height="1000%"
												filterUnits="objectBoundingBox"
												id="dropshadow-1"
											>
												<feOffset
													dy="16"
													in="SourceAlpha"
													result="shadowOffsetOuter"
												/>
												<feGaussianBlur
													stdDeviation="24"
													in="shadowOffsetOuter"
													result="shadowBlurOuter"
												/>
												<feColorMatrix
													values="0 0 0 0 0.10 0 0 0 0 0.17 0 0 0 0 0.21 0 0 0 0.22 0"
													in="shadowBlurOuter"
												/>
											</filter>
										</defs>

										<g fill="none" fill-rule="evenodd">
											<rect
												width="450"
												height="450"
												rx="2"
												fill="#FFF"
												style="
													filter: url(#dropshadow-1);
												"
											/>
											<image
												width="500"
												height="500"
												x="0"
												y="-35"
												xlink:href="https://i.ibb.co/k04zjth/Whats-App-Image-2021-02-20-at-19-00-12.jpg"
											/>

											<g
												filter="url(#browser-d)"
												transform="translate(505 196)"
												fill-rule="nonzero"
											>
												<path
													d="M100 100l100-50.426L103.193.762a7.087 7.087 0 0 0-6.393 0L0 49.574 100 100z"
													fill="url(#browser-e)"
												/>
												<path
													d="M199 90L99 139.875v-40L199 50v40z"
													fill="url(#browser-f)"
												/>
												<path
													d="M100 139.875L0 90V50l100 49.875v40z"
													fill="url(#browser-g)"
												/>
											</g>
											<g stroke-width="2">
												<path
													stroke="url(#browser-h)"
													d="M498.336 298.7l-62.117 30.105L194 208"
													transform="translate(169 54)"
												/>
												<path
													d="M511.219 127.805L269 7"
													stroke="url(#browser-i)"
													transform="translate(169 54)"
												/>
												<path
													d="M312 157.547L533.512 43"
													stroke="url(#browser-j)"
													transform="translate(169 54)"
												/>
												<path
													d="M222 114.547L443.512 0"
													stroke="url(#browser-k)"
													transform="translate(169 54)"
												/>
												<path
													d="M0 356.547L221.512 242"
													stroke="url(#browser-l)"
													transform="translate(169 54)"
												/>
												<path
													d="M215 319.266L312.031 268"
													stroke="url(#browser-j)"
													transform="translate(169 54)"
												/>
											</g>
											<g
												filter="url(#browser-m)"
												transform="scale(-1 1) rotate(45 -338.122 -406.594)"
												fill-rule="nonzero"
											>
												<path
													d="M52 0L.511 70.268a2.668 2.668 0 0 0-.478 1.987 2.63 2.63 0 0 0 1.076 1.732L52 110V0z"
													fill="url(#browser-e)"
												/>
												<path
													d="M103.49 70.27L52 0v110l50.89-36.011a2.637 2.637 0 0 0 1.077-1.732 2.669 2.669 0 0 0-.476-1.987z"
													fill="url(#browser-n)"
												/>
											</g>
										</g>
									</svg>
								</div>
								<div class="hero-ball hero-ball-3 is-revealing">
									<svg
										width="80"
										height="80"
										viewBox="0 0 80 80"
										xmlns="http://www.w3.org/2000/svg"
									>
										<defs>
											<radialGradient
												cx="56.15%"
												cy="27.43%"
												fx="56.15%"
												fy="27.43%"
												r="57.526%"
												gradientTransform="matrix(.5626 -.82673 .8022 .54591 .026 .589)"
												id="ball-3-a"
											>
												<stop
													stop-color="#F8F7FF"
													offset="0%"
												/>
												<stop
													stop-color="#DAD8FF"
													offset="34.827%"
												/>
												<stop
													stop-color="#9B95F3"
													offset="100%"
												/>
											</radialGradient>
											<filter
												x="-500%"
												y="-500%"
												width="1000%"
												height="1000%"
												filterUnits="objectBoundingBox"
												id="dropshadow-ball-3"
											>
												<feOffset
													dx="24"
													dy="24"
													in="SourceAlpha"
													result="shadowOffsetOuter"
												/>
												<feGaussianBlur
													stdDeviation="24"
													in="shadowOffsetOuter"
													result="shadowBlurOuter"
												/>
												<feColorMatrix
													values="0 0 0 0 0.10 0 0 0 0 0.17 0 0 0 0 0.21 0 0 0 0.22 0"
													in="shadowBlurOuter"
												/>
											</filter>
										</defs>
										<circle
											cx="40"
											cy="40"
											r="40"
											fill="#FFF"
											fill-rule="evenodd"
											style="
												filter: url(#dropshadow-ball-3);
											"
										/>
										<circle
											cx="40"
											cy="40"
											r="40"
											fill="url(#ball-3-a)"
											fill-rule="evenodd"
										/>
									</svg>
								</div>
								<div class="hero-ball hero-ball-4 is-revealing">
									<svg
										width="40"
										height="40"
										viewBox="0 0 40 40"
										xmlns="http://www.w3.org/2000/svg"
									>
										<defs>
											<radialGradient
												cx="56.15%"
												cy="27.43%"
												fx="56.15%"
												fy="27.43%"
												r="57.526%"
												gradientTransform="matrix(.5626 -.82673 .8022 .54591 .026 .589)"
												id="ball-4-a"
											>
												<stop
													stop-color="#F8F7FF"
													offset="0%"
												/>
												<stop
													stop-color="#DAD8FF"
													offset="34.827%"
												/>
												<stop
													stop-color="#9B95F3"
													offset="100%"
												/>
											</radialGradient>
											<filter
												x="-500%"
												y="-500%"
												width="1000%"
												height="1000%"
												filterUnits="objectBoundingBox"
												id="dropshadow-ball-4"
											>
												<feOffset
													dx="24"
													dy="24"
													in="SourceAlpha"
													result="shadowOffsetOuter"
												/>
												<feGaussianBlur
													stdDeviation="24"
													in="shadowOffsetOuter"
													result="shadowBlurOuter"
												/>
												<feColorMatrix
													values="0 0 0 0 0.10 0 0 0 0 0.17 0 0 0 0 0.21 0 0 0 0.22 0"
													in="shadowBlurOuter"
												/>
											</filter>
										</defs>
										<circle
											cx="20"
											cy="20"
											r="20"
											fill="#FFF"
											fill-rule="evenodd"
											style="
												filter: url(#dropshadow-ball-4);
											"
										/>
										<circle
											cx="20"
											cy="20"
											r="20"
											fill="url(#ball-4-a)"
											fill-rule="evenodd"
										/>
									</svg>
								</div>
								<div class="hero-ball hero-ball-5 is-revealing">
									<svg
										width="24"
										height="24"
										viewBox="0 0 24 24"
										xmlns="http://www.w3.org/2000/svg"
									>
										<defs>
											<radialGradient
												cx="56.15%"
												cy="27.43%"
												fx="56.15%"
												fy="27.43%"
												r="57.526%"
												gradientTransform="matrix(.5626 -.82673 .8022 .54591 .026 .589)"
												id="ball-5-a"
											>
												<stop
													stop-color="#F8F7FF"
													offset="0%"
												/>
												<stop
													stop-color="#DAD8FF"
													offset="34.827%"
												/>
												<stop
													stop-color="#9B95F3"
													offset="100%"
												/>
											</radialGradient>
											<filter
												x="-500%"
												y="-500%"
												width="1000%"
												height="1000%"
												filterUnits="objectBoundingBox"
												id="dropshadow-ball-5"
											>
												<feOffset
													dx="24"
													dy="24"
													in="SourceAlpha"
													result="shadowOffsetOuter"
												/>
												<feGaussianBlur
													stdDeviation="24"
													in="shadowOffsetOuter"
													result="shadowBlurOuter"
												/>
												<feColorMatrix
													values="0 0 0 0 0.10 0 0 0 0 0.17 0 0 0 0 0.21 0 0 0 0.22 0"
													in="shadowBlurOuter"
												/>
											</filter>
										</defs>
										<circle
											cx="12"
											cy="12"
											r="12"
											fill="#FFF"
											fill-rule="evenodd"
											style="
												filter: url(#dropshadow-ball-5);
											"
										/>
										<circle
											cx="12"
											cy="12"
											r="12"
											fill="url(#ball-5-a)"
											fill-rule="evenodd"
										/>
									</svg>
								</div>
							</div>
						</div>
					</div>
				</section>
			</main>

			<footer class="site-footer text-light" style="margin-top: -12%">
				<div class="container">
					<div class="site-footer-inner">
						<ul class="footer-links list-reset">
							<li>
								<a
									style="
										color: #04ffeaa8;
										font-weight: 400;
										text-shadow: 1px 2px 2px #1a1a1aa8;
									"
									href="tel:+91 8595591496"
									>Contact</a
								>
							</li>
							<li>
								<a
									style="
										color: #04ffeaa8;
										font-weight: 400;
										text-shadow: 1px 2px 2px #1a1a1aa8;
									"
									href="https://www.instagram.com/nowcare4u/?hl=en"
									>Instagram</a
								>
							</li>
							<li>
								<a
									style="
										color: #04ffeaa8;
										font-weight: 400;
										text-shadow: 1px 2px 2px #1a1a1aa8;
									"
									href="https://www.linkedin.com/company/nowcare4u/"
									>LinkedIn</a
								>
							</li>
							<li>
								<a
									style="
										color: #04ffeaa8;
										font-weight: 400;
										text-shadow: 1px 2px 2px #1a1a1aa8;
									"
									href="https://twitter.com/nowcare4u?lang=en"
									>Twitter</a
								>
							</li>
							<li>
								<a
									style="
										color: #04ffeaa8;
										font-weight: 400;
										text-shadow: 1px 2px 2px #1a1a1aa8;
									"
									href="https://www.facebook.com/nowcare4u/"
									>Facebook</a
								>
							</li>
						</ul>
					</div>
				</div>
			</footer>
		</div>

		<script src="dist/js/main.min.js"></script>
	</body>
</html>
