<!-- @format -->

<div id="top" class="scrollbar1">
	<div class="overflow">
		<div
			*ngIf="myArray != null"
			class="container img-fluid p-3 my-3 bg-white text-black"
		>
			<div *ngIf="show" class="spinner">
				<div><mat-spinner></mat-spinner>Loading... Please wait</div>
			</div>
			<h1>{{ myArray.title }}</h1>
			<h4 class="text-secondary">by {{ myArray.createdBy }}</h4>
			<div
				[innerHTML]="myArray.content"
				class="contuuuu"
				style="overflow: hidden"
			></div>
		</div>
	</div>
</div>
