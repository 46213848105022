/** @format */

import { Injectable } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { Employee } from "./employee.model";

@Injectable({
	providedIn: "root",
})
export class EmployeeService {
	constructor(private firestore: AngularFirestore) {}
	getEmployees() {
		return this.firestore.collection("Program").snapshotChanges();
	}
	getEmployee(id) {
		return this.firestore.collection("Program").doc(id);
	}
	updateEmployee(employee, id) {
		const employeeObject = { ...employee };
		this.firestore.doc("Program/" + id).update(employeeObject);
	}
	getActive(role){
		return this.firestore
			.collection("Accounts")
			.doc(role)
			.collection('Users')
			.snapshotChanges();
	}
}
