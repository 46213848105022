import {
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { MediaMatcher } from '@angular/cdk/layout';
import { MenuItems } from '../../../shared/menu-items/menu-items';
import { LoginService } from '../../../login.service';
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: [],
  providers: [LoginService]
})
export class AppSidebarComponent implements OnDestroy, OnInit {
  mobileQuery: MediaQueryList;
  name: string;
  dname: string;
  private _mobileQueryListener: () => void;
  login = false;
  dlogin = false;
  admin: any;
  isadmin= false;
  isowner = false;
  isvendor = false;

  constructor(
    public logSer: LoginService,
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    public menuItems: MenuItems
  ) {
    this.mobileQuery = media.matchMedia('(min-width: 768px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);

    this.logSer.userLog.subscribe(data => {
      if (data != null) {
        this.name = data.email;
        console.log(data);
        this.logSer.roleSub.subscribe(value =>{
              this.isadmin = value;
              

        });
        this.logSer.ownerSub.subscribe(value =>{
          console.log('owners');
          this.isowner = value;

        
        });
        this.logSer.vendorSub.subscribe(value =>{
          console.log('vendors');
          this.isvendor = value;

        });
      }
    });
  this.logSer.doctorLog.subscribe(doc => {
    if (doc != null) {
      this.dname = doc.name;
    }
    });

    this.logSer.loginSub.subscribe(data => {
        this.login = data;

    });
  this.logSer.doctorSub.subscribe(doc => {

      this.dlogin = doc;

  });


  }

  ngOnInit() {

  }
  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
    this.isadmin = false;
    this.isowner = false;
    this.isvendor = false;
  }}