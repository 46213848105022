/** @format */

import { AuthGuard } from "./auth.guard";
import { LoginService } from "./login.service";
import {
	AngularFireStorage,
	AngularFireStorageModule,
} from "@angular/fire/storage";
import { AngularFireAuth, AngularFireAuthModule } from "@angular/fire/auth";
import {
	AngularFirestore,
	AngularFirestoreModule,
} from "@angular/fire/firestore";
import { AppRoutingModule } from "./app-routing.module";

import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { PreloadAllModules, RouterModule } from "@angular/router";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
import { LocationStrategy, PathLocationStrategy } from "@angular/common";
import { AppRoutes } from "./app.routing";
import { AppComponent } from "./app.component";
import { FlexLayoutModule } from "@angular/flex-layout";
import { FullComponent } from "./layouts/full/full.component";
import { AppHeaderComponent } from "./layouts/full/header/header.component";
import { AppSidebarComponent } from "./layouts/full/sidebar/sidebar.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { DemoMaterialModule } from "./demo-material-module";
import { SharedModule } from "./shared/shared.module";
import { SpinnerComponent } from "./shared/spinner.component";
import { FooterComponent } from "./layouts/full/footer/footer.component";
import { LoginComponent } from "./login/login.component";
import { SignUpComponent } from "./sign-up/sign-up.component";
import { MenuBarComponent } from "./menu-bar/menu-bar.component";
import { NavbarComponent } from "./navbar/navbar.component";
import { HashLocationStrategy } from "@angular/common";
import { environment } from "../environments/environment";
import { FireAdminModule } from "ng-fire-admin";
import { AngularFireModule } from "@angular/fire";
import { PageComponent } from "./page/page.component";
import { ForgotpwdComponent } from "./forgotpwd/forgotpwd.component";
import { NgxGoogleAnalyticsModule } from "ngx-google-analytics";
import { LandingComponent } from "./landing/landing.component";
import { ActiveComponent } from './active/active.component';
import { Landing2Component } from './landing2/landing2.component';

@NgModule({
	declarations: [
		AppComponent,
		FullComponent,
		AppHeaderComponent,
		SpinnerComponent,
		AppSidebarComponent,
		FooterComponent,
		LoginComponent,
		SignUpComponent,
		MenuBarComponent,
		NavbarComponent,
		PageComponent,
		ForgotpwdComponent,
		LandingComponent,
		ActiveComponent,
		Landing2Component,
	],
	imports: [
		BrowserModule,
		NgxGoogleAnalyticsModule.forRoot("G-8S5TDHSBTE"),
		AppRoutingModule,
		ReactiveFormsModule,
		BrowserAnimationsModule,
		DemoMaterialModule,
		FormsModule,
		FlexLayoutModule,
		HttpClientModule,
		SharedModule,
		AngularFireAuthModule,
		AngularFirestoreModule,
		AngularFireStorageModule,
		FireAdminModule.initialize(environment.firebase),
		AngularFireModule.initializeApp(environment.firebase),
	],
	providers: [
		{ provide: LocationStrategy, useClass: PathLocationStrategy },
		AngularFireAuth,
		AngularFireStorage,
		AngularFirestore,
		LoginService,
		AuthGuard,
	],
	bootstrap: [AppComponent],
})
export class AppModule {}
